<template>
   <div class="Applications2">
             <div class="introduceT">
                  <div class="introduceTN" v-for="(item,index) in pdflist" :key="index">
                           <div class="introduceTNname">{{item.name}}</div>
                           <div class="introduceTNC">
                                   <div class="introduceTNC1" > <a  :href="item.view" src="">View</a> </div>
                                  
                                   <div class="introduceTNC1 TNC2" ><a :href="item.download" >Download</a></div>
                           </div>
                      </div>
                      
                </div>
        </div>
</template>
<script>
export default {
data() {
return {
    
} 
},
methods: {},
mounted() {},
created() {},
props:{
     pdflist:{
         
     }
},
watch:{ },
components:{}
}
</script>
<style lang="scss" scoped>
.Applications2{
        width: 100%;
        // margin: 20px;
              background: #282829;
              padding: 20px 0;
              margin: 60px 0;
        
        .introduceT{
            // width: 1280px;
            //  margin: 84px auto;
          
    
        }
        .introduceTNname{
          
            //font-family: Work Sans;
            font-weight: bold;
            color: white;
           
            transition: 0.55s;
            width: 50%;
            // padding-bottom:7px ;
            border-bottom: 1px solid #DA251D;
         
        }
        .introduceTNC1{
        //  margin-left:10px; 
            border-bottom: 1px solid #DA251D;
            cursor: pointer;
            line-height: 49px;
            
            a{
              color: white;
            }
        }
 
        .introduceTNC{
            // width: 20%;
            display: flex;
            transition: 0.2s;
            align-items: center;
         
        }
        .introduceTN{
            display: flex;
            align-items: center;
            justify-content: space-between;

        }
    }


@media screen and (max-width: 760px) {
    .TNC2{
         margin-left:10px; 
    }
.introduceT{
            width:90%;
            min-width: 330px;
                       margin: 40px auto;
         
          
        }
        .introduceTN{
            margin: 20px 0
        }
        .introduceTNname{
            font-size: 15px;
              line-height: 30px;
        }
        .introduceTNC{
            font-size: 13px
        }
}


@media screen and (min-width: 760px) and (max-width: 1020px) {
      .TNC2{
         margin-left:20px; 
    }
   .introduceTNname{
            font-size: 18px;
            line-height:60px ;
        }
        .introduceTNC{
            font-size: 12px
        }
         .introduceT{
            width:90%;
                       margin: 60px auto;
      
          
        }
}

@media screen and (min-width: 1020px) and (max-width: 1350px) {
         .TNC2{
         margin-left:20px; 
    }
  .introduceT{
            width:90%;
                       margin: 64px auto;
      
          
        }
          .introduceTNname{
            font-size: 20px;
                line-height:49px ;
        }
        .introduceTNC1{
            line-height: 49px;
        }
        .introduceTNC{
            font-size: 14px
        }
}

@media screen and (min-width: 1350px) {
    .TNC2{
         margin-left:20px; 
    }
  .introduceT{
            width: 1280px;
                margin: 84px auto;
          
          
        }
          .introduceTNname{
            font-size: 21px;
              line-height:49px ;
        }
        .introduceTNC{
            font-size: 16px
        }
}



</style>