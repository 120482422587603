<template>
<div class='page'>
    <div class="content">
        <div class="H1">TECHNICAL SPECIFICATIONS</div>
        <div class="list">
            <div class="model" v-for="(item,index) in SpecificationsLists" :key="index">
                <div class="name">{{item.name}}</div>
                <div class="info">{{item.info}}</div>
            </div>
             
        </div>

    </div>
</div>
</template>
<script>
export default {
data() {
return {
 
}
},
methods: {},
mounted() {},
created() {},
props:{SpecificationsLists:{}},
watch:{ },
components:{}
}
</script>
<style scoped>
.page{
    width: 100%;
    margin: 85px 0;
}
.content{
    width: 1200px;
    margin: auto
}
.model{
    display: flex;
    margin-bottom:10px 
}
.H1{
    font-size: 34px;
 
font-weight: bold;
color: #DA251D;
text-align: center;
margin-bottom:50px 
}
.name{

    width: 31%;
    font-size: 16px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #191919;
 
/* background: red */
 
}
.model{
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    border-bottom:1px solid  #999999a3
}
.info{
    width: 66%;
    font-size: 16px;
font-family: Work Sans;
font-weight: 400;
color: #191919;
 line-height: 24px;
}

 @media screen and (max-width: 760px) {
     .model{
         align-items: center
     }
      .content{
        width: 90%;
        min-width: 330px
    }
    .info{
        font-size: 13px
    }
    .name{
        font-size: 13px;
              width: 28%
    }
     .H1{
        font-size: 25px;
            margin-bottom: 25px;
    }

}
@media screen and (min-width: 760px) and (max-width: 1020px) {
    .H1{
        font-size: 25px
    }
  .content{
        width: 90%;
    }
    .info{
        font-size: 14px
    }
    .name{
        font-size: 14px;
  
    }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .H1{
        font-size: 28px
    }
    .content{
        width: 90%;
    }

}
@media screen and (min-width: 1350px) {
    
}

</style>