<template>
<div class='page'>
      <div class="title1" v-html="title"></div>
</div>
</template>
<script>
export default {
data() {
return {
    //  title:`Rapid prototyping via CNC machining delivers high speed, high precision, and robust rapid part manufacturing solutions. CNC metal processing is a metal processing method such as turning, boring, milling and grinding of metal materials with various CNC machine tools. It is mainly used for the forming and manufacturing of metal products.</br></br>At HLH, over 100 high precision CNC mills, lathes, sink and wire EDMs, and surface grinding machines help us deliver customCNC machining for many industries.`
}

},
methods: {},
mounted() {},
created() {},
props:{
  title:{}
},
watch:{ },
components:{}
}
</script>
<style scoped>
.page{}
.title1{
  width: 991px;
 
  text-align:center;
  font-size: 17px;
font-family: Work Sans;
font-weight: 400;
color: #191919;
line-height: 33px;
 margin: 51px auto;
}
 @media screen and (max-width: 760px) {
.title1{
  width: 90%;
  min-width: 330px;
   font-size: 15px;
    margin: 31px auto;
}
}
@media screen and (min-width: 760px) and (max-width: 1020px) {
.title1{
  width: 90%;
}
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
  
.title1{
  width: 90%;
}
}
@media screen and (min-width: 1350px) {
    
}
</style>