<template>
  <div class="upload" id="_upload">
    <!--这个决定上传文件名字-->
    <form name=theform v-show="flag">
      <input type="radio" name="myradio" value="local_name"/> 上传文件名字保持本地文件名字
      <input type="radio" name="myradio" value="random_name" checked="true"/> 上传文件名字是随机文件名, 后缀保留
    </form>
    <!--文件队列-->
    <h4 v-show="flag">您所选择的文件列表：</h4>
    <!--<div id="ossfile">你的浏览器不支持flash,Silverlight或者HTML5！</div>-->
    <div id="ossfile" v-show="flag"></div>
    <div id="container">
      <p title="最多上传10个3D文件" id="selectfiles" class='btn'  @mousedown="getsign">{{ title }}</p>
      <a id="postfiles" href="javascript:void(0);" class='btn' v-show="flag">开始上传</a>
    </div>

    <!--提示语-->
    <pre id="console" v-show="flag"></pre>

    <p v-show="flag">&nbsp;</p>
  </div>
</template>

<script>
  // 导入plupload包
  import plupload from 'plupload'
  import * as $ from 'jquery'

  var fileArr = [];
  export default {
		name: "uploadoss",
		data() {
			return {
				// 将不需要显示的控件隐藏
				flag:false,
				obj: {},
				uploadPercent: 0,
				// 保存页面需要渲染的文件数据
				// ossurldata:[],
			}
		},
		props: [ 'title', 'url' ],
		methods: {
			getsign () {
				var that = this
				this.$request.post('/'+this.url,{})
				.then((response) => {
				this.$emit('reFlag',true);
				if (response.code == 0) {
					$('.moxie-shim-html5').remove();
					var obj = response.obj
				
				
					var accessid = ''
					// eslint-disable-next-line no-unused-vars
					var accesskey = ''
					var host = ''
					var policyBase64 = ''
					var signature = ''
					// eslint-disable-next-line no-unused-vars
					var callbackbody = ''
					// eslint-disable-next-line no-unused-vars
					var filename = ''
					var key = ''
					var expire = 0
					var g_object_name = ''
					var g_object_name_type = ''
					var timestamp = Date.parse(new Date()) / 1000;
					var now = timestamp;

					// eslint-disable-next-line no-inner-declarations
					function check_object_radio() {
					var tt = document.getElementsByName('myradio');
					for (var i = 0; i < tt.length; i++) {
						if (tt[i].checked) {
						g_object_name_type = tt[i].value;
						break;
						}
					}
					}
	
					// eslint-disable-next-line no-inner-declarations
					function get_signature() {
					// 可以判断当前expire是否超过了当前时间， 如果超过了当前时间， 就重新取一下，3s 作为缓冲。
					now = timestamp = Date.parse(new Date()) / 1000;
					if (expire < now + 3) {
						// body = send_request()
					
						// var obj = eval ("(" + body + ")").obj;
					
	
						host = obj['host']
					
						policyBase64 = obj['policy']
						accessid = obj['accessid']
						signature = obj['signature']
						expire = parseInt(obj['expire'])
						callbackbody = obj['callback']
						key = obj['dir']
					
	
						return true;
					}
					return false;
					}
	
					// eslint-disable-next-line no-inner-declarations
					function random_string(len) {
					len = len || 32;
					var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
					var maxPos = chars.length;
					var pwd = '';
					for (let i = 0; i < len; i++) {
						pwd += chars.charAt(Math.floor(Math.random() * maxPos));
					}
					return pwd;
					}
	
					// eslint-disable-next-line no-inner-declarations
					function get_suffix(filename) {
					let pos = filename.lastIndexOf('.')
					let suffix = ''
					if (pos != -1) {
						suffix = filename.substring(pos)
					}
					return suffix;
					}
	
					// eslint-disable-next-line no-inner-declarations
					function calculate_object_name(filename) {
					if (g_object_name_type == 'local_name') {
						g_object_name += "${filename}"
					}
					else if (g_object_name_type == 'random_name') {
						let suffix = get_suffix(filename)
						g_object_name = key + random_string(10) + suffix
					}
					return ''
					}
	
					// eslint-disable-next-line no-inner-declarations
					function get_uploaded_object_name(filename) {
					if (g_object_name_type == 'local_name') {
						let tmp_name = g_object_name
						tmp_name = tmp_name.replace("${filename}", filename);
						return tmp_name
					}
					else if (g_object_name_type == 'random_name') {
						return g_object_name
					}
					}
	
					// eslint-disable-next-line no-inner-declarations
					function set_upload_param(up, filename, ret) {
					if (ret == false) {
						ret = get_signature()
					}
					g_object_name = key;
					if (filename != '') {
						// eslint-disable-next-line no-unused-vars
						let suffix = get_suffix(filename)
						calculate_object_name(filename)
					}
					let new_multipart_params = {
						'key': g_object_name,
						'policy': policyBase64,
						'OSSAccessKeyId': accessid,
						'success_action_status': '200', //让服务端返回200,不然，默认会返回204
						// 'callback' : callbackbody,
						'signature': signature,
					};
	
					up.setOption({
						'url': host,
						'multipart_params': new_multipart_params
					});
	
					up.start();
					}
	
				
					var uploader = new plupload.Uploader({
					runtimes: 'html5,flash,silverlight,html4',
					browse_button: 'selectfiles',
					multi_selection: false,
					container: document.getElementById('container'),
					flash_swf_url: 'lib/plupload-2.1.2/js/Moxie.swf',
					silverlight_xap_url: 'lib/plupload-2.1.2/js/Moxie.xap',
					url: 'http://oss.aliyuncs.com',
	
					filters: {
						mime_types: [ //只允许上传图片和zip文件和stl文件
						// {title: "Image files", extensions: "jpg,gif,png,bmp"},
						// {title: "Zip files", extensions: "zip,rar"},
						// {title: "THREE files", extensions: "stl"}
						],
						max_file_size: '100mb', //最大只能上传100mb的文件
						prevent_duplicates: true//是否允许选取重复文件
					},
	
					max_retries: 3, //请求错误时的重试次数，为0时表示不重试
	
					init: {
						// PostInit: function () {
					
						
						//   document.getElementById('ossfile').innerHTML = '';
						//   document.getElementById('postfiles').onclick = function () {
						
						//     set_upload_param(uploader, '', false);
						//     return false;
						//   };
						// },
						PostInit:()=> {
					
						document.getElementById('ossfile').innerHTML = '';
						// document.getElementById('postfiles').onclick = function () {
						//   set_upload_param(uploader, '', false);
						//   return false;
						// };
						},
	
						FilesAdded:  (up, files)=> {
						console.log('第二步,有新文件添加至队列');
					
						set_upload_param(uploader, '', false);
					
						//向父组件发送数据
						this.$emit('getossdata',files);
						plupload.each(files,  (file)=> {
							document.getElementById('ossfile').innerHTML += '<div id="' + file.id + '">' + file.name + ' (' + plupload.formatSize(file.size) + ')<b></b>'
							+ '<div class="progress"><div class="progress-bar" style="width: 0%"></div></div>'
							+ '</div>';
						});
						this.uploadPercent = 0
						// that.$toast.loading({
						// 	message: '上传中...',
						// 	forbidClick: true,
						// 	loadingType: 'spinner',
						// 	duration:0,
						// });
						// that.$vux.loading.show({
						// 	text: '上传中 '+that.uploadPercent+'%'
						// })
						},
	
						BeforeUpload: function (up, file) {
						check_object_radio();
						set_upload_param(up, file.name, true);
	
						},
	
						UploadProgress:  (up, file)=> {
						// that.$vux.loading.show({
						// 	text: '上传中 '+that.uploadPercent+'%'
						// })
						that.uploadPercent = file.percent
						this.$emit('getprogressbar',file.id,file.percent)
					
						var d = document.getElementById(file.id);
						d.getElementsByTagName('b')[0].innerHTML = '<span>' + file.percent + "%</span>";
						var prog = d.getElementsByTagName('div')[0];
						// progBar是上传进度
						var progBar = prog.getElementsByTagName('div')[0]
					
						progBar.style.width = 2 * file.percent + 'px';
						progBar.setAttribute('aria-valuenow', file.percent);
						
						},
	
						FileUploaded:  (up, file, info) =>{
						if (info.status == 200) {
							document.getElementById(file.id).getElementsByTagName('b')[0].innerHTML = 'upload to oss success, object name:' + get_uploaded_object_name(file.name) + ' 回调服务器返回的内容是:' + JSON.stringify(info);
							
							// that.$oast.clear({})
							// 获取上传后文件的id
							// eslint-disable-next-line no-unused-vars
							var igsCanvasId = file.id;
						
							// 获取上传后文件的路径
							var ossPath = host + '/' + get_uploaded_object_name(file.name);
	
							var obj = {};
							obj.fileName = file.name;
							obj.path = host + '/' + get_uploaded_object_name(file.name);
							// obj.path = host + '/' + response.obj.dir + get_uploaded_object_name(file.name);
							obj.imgUrl = ossPath
							if(uploader.multi_selection){
								fileArr.push(obj)
							}else{
								fileArr.splice(0,1,obj)
							}
							//将路径添加到
							this.$emit('getossurl',fileArr);
							// this.$toast.clear();
						}
						else if (info.status == 203) {
							document.getElementById(file.id).getElementsByTagName('b')[0].innerHTML = '上传到OSS成功，但是oss访问用户设置的上传回调服务器失败，失败原因是:' + info.response;
						}
						else {
							document.getElementById(file.id).getElementsByTagName('b')[0].innerHTML = info.response;
						}
						// that.$vux.loading.hide()
						// this.$vux.toast.show({
						// 	type: 'success',
						// 	text: '已上传'
						// })
						},
	
						Error: function (up, err) {
						
						// that.$vux.loading.hide()
						// this.$vux.toast.show({
						// 	type: 'cancel',
						// 	text: '上传出错啦'
						// })
						if (err.code == -600) {
							document.getElementById('console').appendChild(document.createTextNode("\n选择的文件太大了,可以根据应用情况，在upload.js 设置一下上传的最大大小"));
						}
						else if (err.code == -601) {
							document.getElementById('console').appendChild(document.createTextNode("\n选择的文件后缀不对,可以根据应用情况，在upload.js进行设置可允许的上传文件类型"));
						}
						else if (err.code == -602) {
							document.getElementById('console').appendChild(document.createTextNode("\n这个文件已经上传过一遍了"));
						}
						else {
							document.getElementById('console').appendChild(document.createTextNode("\nError xml:" + err.response));
						}
						}
					}
					});
					
	
					uploader.init();
				}
				else {
					return;
				}
				})
			}
		}
  }
</script>

<style scoped lang="scss">

  .upload {
		overflow: hidden;
		float: left;
		font-size: 12px;
		width: 2.5rem;
		height: .8rem;
		background: #f23f3f;
		color: #fff;
		line-height:.8rem;
		text-align: center;
		border-radius: 5px;
		margin-right: 30px;
		cursor: pointer;

    a {   	color: #0c0c0c;
    }
    .btn {
		width: 100%;
		line-height: 22px;
		// color: #fff;
		// background-color: #f23f3f;
		// border-color: #f23f3f;
		margin-bottom: 0;
		font-size: 12px;
		text-align: center;
		white-space: nowrap;
		text-decoration: none;
		vertical-align: middle;
		background-image: none;
		border: 1px solid transparent;
		border-radius: 4px;
    }
    .progress {
		margin-top: 2px;
		width: 200px;
		height: 14px;
		margin-bottom: 10px;
		overflow: hidden;
		background-color: #f5f5f5;
		border-radius: 4px;
		-webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    }
    .progress-bar {
		background-color: rgb(92, 184, 92);
		background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.14902) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.14902) 50%, rgba(255, 255, 255, 0.14902) 75%, transparent 75%, transparent);
		background-size: 40px 40px;
		box-shadow: rgba(0, 0, 0, 0.14902) 0px -1px 0px 0px inset;
		box-sizing: border-box;
		color: rgb(255, 255, 255);
		display: block;
		float: left;
		font-size: 12px;
		height: 20px;
		line-height: 20px;
		text-align: center;
		transition-delay: 0s;
		transition-duration: 0.6s;
		transition-property: width;
		transition-timing-function: ease;
		width: 266.188px;
    }
  }


</style>
