<template>
<div class='page'>
    <div class="content">
         <div class="bg"> <img src="../assets/img/Horizontal bar.png" alt=""> </div> 
        </div>
</div>
</template>
<script>
export default {
data() {
return {}
},
methods: {},
mounted() {},
created() {},
props:{},
watch:{ },
components:{}
}
</script>
<style scoped>
.page{
    margin: 80px 0;
    overflow: hidden;
}
.content{
    width: 100%;
      height: auto;
    background: #282828
 
    
 

}
.bg{
    width:600px ;
    height: 190px;
    margin: auto
}
img{
    width: 100%;
    height: 100%;
}
 @media screen and (max-width: 760px) {

}
@media screen and (min-width: 760px) and (max-width: 1020px) {

}
@media screen and (min-width: 1020px) and (max-width: 1350px) {

}
@media screen and (min-width: 1350px) {
    
}









 
</style>