<template>
<div class='page'>
    <div class="content">
        <div class="H1">PRODUCTS DISPLAY</div>
        <div class="lists">
            <div class="model" v-for="(item,index) in displaylists" :key="index">
                <div class="modelImg"><img :src="item.img" alt=""></div>
                <div class="modelname">{{item.name}}</div>
                
            </div>
         

        </div>

    </div>
</div>
</template>
<script>
export default {
data() {
return {
  
}
},
methods: {},
mounted() {},
created() {},
props:{displaylists:{}},
watch:{ },
components:{}
}
</script>
<style scoped>
.page{
    width: 100%;
}
img{
    width: 100%
}
.model{
     
    width: 360px;
    border: 1px solid #E7E7E7;
    margin-bottom: 41px
 
}
.content{
    width: 1200px;
    margin: auto
}
.modelImg{
    width: 100%;
  
}
.H1{
    font-size: 34px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #DA251D;
text-align: center;
margin-bottom: 60px
}
.modelname{
    height: 40px;
    line-height: 40px;
    padding:0 11px 
}
.lists{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
 @media screen and (max-width: 760px) {
  .content{
        width: 90%;
     min-width: 330px
          
       
    }
    .model{
        width: 100%;
    }
     .H1{
        font-size: 26px;
        margin-bottom: 30px;
    }
}
@media screen and (min-width: 760px) and (max-width: 1020px) {
   .content{
        width: 90%;
    
    }
    .model{
        width: 47%;
    }
     .H1{
        font-size: 26px
    }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .H1{
        font-size: 28px
    }
    .content{
        width: 90%
    }
    .model{
        width: 30%;
    }

}
@media screen and (min-width: 1350px) {
    
}

</style>