<template>
<div class='page'>
    <div class="content">
        <div class="contenttop">
              <div class="left">
                  <div class="leftTitle">{{ProcessingList.H1}}</div>
                  <div class="leftTitle2">{{ProcessingList.leftTitle2}}</div>
                  <div class="lists">
                      <div class="model" v-for="(item,index) in ProcessingList.lists" :key="index">
                          <div class="img"> <img src="../assets/img/gou.png" alt=""></div>
                          <div class="info">{{item.info}}</div>
                      </div>
                      
                  </div>
              </div>
              <div class="rigImg">
                  <img :src="ProcessingList.srcData" alt="">
              </div>
        </div>
        <div class="contentImg">
                <div class="contentImgmodel" v-for="(item1,index1) in ProcessingList.imgs" :key="index1"><img :src="item1.img" alt=""></div>
                 
        </div>
    </div>
</div>
</template>
<script>
export default {
data() {
return {
  
}
},
methods: {},
mounted() {},
created() {},
props:{ProcessingList:{}},
watch:{ },
components:{}
}
</script>
<style scoped>
.contentImgmodel{
    width: 340px;
 
}
img{
    width: 100%;
}
.contentImg{
    display: flex;
    justify-content: space-between;
    margin: 46px auto
}
.page{
    width: 100%;
    background:#282829; 
    padding: 47px 0;
    margin: 63px 0
}
.content{
    width: 1200px;
    margin: auto;

}
.contenttop{
    width: 85%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}
.left{
    width: 470px;
}
.lists{
    padding-top: 10px 
}
.model{
    display: flex;
    margin: 10px 0;
    align-items: top

}
.leftTitle2{
    font-size: 18px;
font-family: DengXian;
font-weight: 400;
color: #FFFFFF;
line-height: 33px;
margin: 20px 0
}
.info{
font-size: 16px;
font-family: Work Sans;
font-weight: 400;
color: #FFFFFF;
line-height: 25px;
}
.leftTitle{
    font-size: 34px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #DA251D;
}
.img{
    margin-right:10px ;
    min-width: 26px;
 
}
.rigImg{
    width: 380px;
}
 @media screen and (max-width: 760px) {
.contenttop{
        display: flex;
      flex-direction: column-reverse;
        
    }
  .content{
        width: 100%;
        min-width: 330px
    }
    .left{
        width: 100%;
    }
    .rigImg{
        width: 100%;
        max-width: 380px
    }
    .leftTitle{
        font-size: 30px
    }
    .leftTitle2{
        font-size: 16px
    }
    .leftTitle{
        margin: 40px 0;
        /* text-align: center */
    }
    .info{
        font-size: 13px
    }
    
 

.contentImg{
    width: 90%;
    display: block;
 
}
.contentImgmodel{
    width: 100%;
    max-width:400px ;
    
    margin:  auto;
    margin-bottom: 30px
}
}
@media screen and (min-width: 760px) and (max-width: 1020px) {
    .contenttop{
        display: flex;
      flex-direction: column-reverse;
        
    }
  .content{
        width: 100%;
    }
    .left{
        width: 100%;
    }
    .rigImg{
        width: 100%;
        max-width: 380px
    }
    .leftTitle{
        font-size: 30px
    }
    .leftTitle2{
        font-size: 16px
    }
    .leftTitle{
        margin: 40px 0;
        text-align: center
    }
    .info{
        font-size: 13px
    }
    
 

.contentImg{
    width: 90%;
    display: block;
 
}
.contentImgmodel{
    width: 100%;
    max-width:400px ;
    
    margin:  auto;
    margin-bottom: 30px
}
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .content{
        width: 90%;
    }
    .left{
        width: 50%;
    }
    .rigImg{
        width: 32vw;
    }
    .leftTitle{
        font-size: 30px
    }
    .leftTitle2{
        font-size: 16px
    }
    .info{
        font-size: 13px
    }

.contentImg{
    width: 90%;
 
}
.contentImgmodel{
    width: 31%;
}
}
@media screen and (min-width: 1350px) {
    
}
</style>